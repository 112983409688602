<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<label for="perpage"></label>
								<select v-model="filter.per_page" class="form-select digits" id="perpage" @change="loadRegisteredPartners">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
						<div class=""></div>
					</div>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom Complet</th>
                                    <th>Nom Business</th>
                                    <th class="bg-light">Type</th>
                                    <th>Localisation</th>
                                    <th>Telephone</th>
                                    <th>Documents</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in registeredPartners.data" :key="index" :class="[!item.is_read ? activeClass : '']">
                                    <td @click="markAsRead(item, true)">{{ index+1 }}</td>
                                    <td @click="markAsRead(item, true)" class="f-w-700">
                                        <feather type="user-check" class="text-info" size="13" v-if="item.userid"></feather>
                                        <span v-if="item.account_profile !== 'Deliver'">
                                            {{ item.firstname }} {{ item.lastname }} <div class="span badge rounded-pill pill-badge-danger" v-if="!item.is_read">Nouveau</div>
                                        </span>
                                        <span v-else>
                                            {{ item.name }}
                                        </span>
                                    </td>
                                    <td @click="markAsRead(item, true)">
                                        <span v-if="item.account_profile !== 'Deliver'">{{ item.name }}</span>
                                        <span v-else>Livreur Allogo</span>
                                    </td>
                                    <td @click="markAsRead(item, true)" :class="[!item.is_read ? activeClass : 'bg-light']">
                                        <div class="span badge rounded-pill pill-badge-primary">{{ item.account_profile }}</div>
                                    </td>
                                    <td @click="markAsRead(item, true)">{{ item.city }} / {{ item.zone }}</td>
                                    <td @click="markAsRead(item, true)">{{ item.phone_number }}</td>
                                    <td class="text-center align-middle">
                                        <span v-if="item.has_document">
                                            <feather type="file" class="text-primary cursor-pointer" size="13" @click="showSingle(item)"></feather>
                                        </span>
                                        <span v-else>...</span>
                                        <span v-if="!item.is_on && item.userid == null">
                                            <feather type="user-plus" class="text-warning cursor-pointer" size="13" @click="showModal(item)"></feather>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="7">
                                        <pagination :data="registeredPartners" @pagination-change-page="loadRegisteredPartners">
                                            <span slot="prev-nav">&lt; Prec</span>
                                            <span slot="next-nav">Suiv &gt;</span>
                                        </pagination>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="showRegistrstionDetails" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background-color:'';">
                <div class="modal-header bg-primary">
                <h5 class="modal-title">Detail de l'inscription</h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
                </div>
                <div class="modal-body">
                    <h6>Information du business</h6>
                    <div class="mt-2 mb-2" style="border-radius: 0px; padding: 2%; background-color: whitesmoke;">
                        <div class="row">
                        <div class="form-group">
                            <label for="">Nom du business</label><br>
                            <span class="text-muted" style="" v-if="RegistrationItem.account_profile !=='Deliver'">{{ RegistrationItem.name }}</span>
                            <span class="text-muted" v-else>Livreur Allogo</span>
                        </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="">Type de business</label><br>
                                <span class="text-muted">{{ RegistrationItem.account_profile }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="">Localisation</label><br>
                                <span class="text-muted">{{ RegistrationItem.city }} / {{ RegistrationItem.zone }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="">Contacts</label><br>
                                <span class="text-muted">{{ RegistrationItem.mail_address }} / {{ RegistrationItem.phone_number }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-grou">
                                <label for="">Document</label>
                                <span class="text-primary">
                                    <span v-if="RegistrationItem.has_document">
                                        <span class="cursor-pointer"  @click="showSingle(RegistrationItem)">
                                            <feather type="file" class="text-primary" size="13"></feather> Fichier
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span class="text-primary">Aucun fichier</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <h6>Informations personnelles</h6>
                    <div class="mt-2 mb-2" style="border-radius: 0px; padding: 2%; background-color: whitesmoke;">
                        <div class="row">
                            <div class="form-group">
                                <label for="">Nom complet</label><br>
                                <span class="text-muted" v-if="RegistrationItem.account_profile !=='Deliver'">{{ RegistrationItem.firstname }} {{ RegistrationItem.lastname }}</span>
                                <span class="text-muted" v-else>{{ RegistrationItem.name }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="">Contacts</label><br>
                                <span class="text-muted" v-if="RegistrationItem.account_profile !=='Deliver'">{{ RegistrationItem.ownermail }} / {{ RegistrationItem.ownernumber }}</span>
                                <span class="text-muted" v-else>{{ RegistrationItem.mail_address }} / {{ RegistrationItem.phone_number }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="modal fade" id="activatePartner" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
            <div class="modal-dialog center" role="document" ref="target" id="target">
                <form @submit.prevent="activateAccount('activatePartner')" ref="content">
                    <div class="modal-content" style="background-color:'';">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title">Activation du compte</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Nom d'utilisateur</label><br>
                                    <input type="text" v-model="account.username" class="form-control form-control-lg" placeholder="Entrer l'identifiant">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Mot de passe</label>
                                    <input type="password" v-model="account.password" class="form-control form-control-lg" placeholder="Entrer le mot de passe">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Roles</label>
                                    <multiselect v-model="account.role" :options="roles" :hide-selected="false" placeholder="Selectionner le role" label="name" track-by="id"></multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <button class="btn btn-outline-primary" type="submit">Activer le compte</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
         ></vue-easy-lightbox>
        <v-dialog />
    </div>
</template>

<script>

    import axios from 'axios';
import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

    export default {
        components: {
            Button, HasError, AlertError
        },
        data: function() {
            return {
                filter: new Form({
                    per_page:10,
                }),
                account: new Form({
                    username:'',
                    password:'',
                    role:'',
                    account_id: '',
                    account_code: '',
                    name:'',
                    profile: '',

                }),
                changestate: new Form({
                    id:'',
                    is_on:'',
                }),
                imgs: '',
                visible: false,
                index: 0, 
                baseUrl: Laravel.baseUrl,
                registeredPartners:[],
                pages: [1,5,10,20,30,40,50,60,70,80,90,100],
                activeClass: 'bg-light-danger cursor-pointer f-s-italic',
                RegistrationItem:[],
                roles:[],
            }
        },
        computed: {
            
        },
        methods: {
            showSingle(item) {
                this.markAsRead(item);
                this.imgs = this.baseUrl+'medias/document/'+item.document
                // or
               // this.imgs = { title: item.name, src: this.baseUrl+'medias/document/'+item.document }
                this.show();
            },
            show() {
                this.visible = true;
            },
            handleHide() {
                this.visible = false
            },
            loadRegisteredPartners: function(page = 1) {
                let uri = "/admin/accounts/registrations/load-registered-partners?page=" + page;
                this.filter.post(uri).then(response => {
                    this.registeredPartners = response.data;
                }).catch(err => {
                    console.log(err);
                });
            },
            markAsRead: function(item, showModal = false) {
                this.RegistrationItem = item;
                if(!item.is_read) {
                    let uri = "/admin/accounts/registrations/mark-as-read";
                    axios.put(uri, {raw: item}).then(response => {
                        Fire.$emit('Refresh-datas');
                        (showModal)? $("#showRegistrstionDetails").modal('show') : null;
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    (showModal)? $("#showRegistrstionDetails").modal('show') : null;
                }
            },
            changeState: function(item, state) {
                let uri = "/admin/accounts/change-partner-state";
                this.changestate.id = item.id;
                //this.changestate.account_id = item.account_id;
                this.changestate.is_on = state;

                this.$modal.show('dialog', {
                title: "Activation de partenaires",
                text: 'Voulez-vous vraiment le rendre disponible?',
                buttons: [
                    {
                    title: 'Non',
                    handler: () => {
                        this.$modal.hide('dialog')
                    }
                    },
                    {
                    title: 'Oui',
                    handler: () => {
                        this.changestate.post(uri).then(response => {
                            Fire.$emit('Refresh-datas');
                        if(response.data.success) {
                            this.changestate.reset();
                        }
                        Toast.fire({
                                icon: response.data.success? 'success':'error',
                                title: response.data.message
                            });
                            this.$modal.hide('dialog'); 

                        });
                    }
                    }
                ]
                });
            },
            activateAccount: function(modal) {
                const loading = this.$vs.loading({type:'square', target: this.$refs.content, color: this.color, text: 'Un instant, activation en cours...'});
                let uri = "/admin/accounts/registrations/activate-partner-account";
                this.account.post(uri).then(response => {
                    loading.close();
                    (response.data.success)? this.account.reset() : null;
                    (response.data.success)? $("#"+modal).hide() : null;
                    Toast.fire({
                        icon: response.data.success? 'success':'error',
                        title: response.data.message
                    });
                }).catch(err => {
                    loading.close();
                    console.log(err);
                });
            },
            showModal: function(item) {
                this.account.account_id = item.id;
                this.account.account_code = item.code;
                this.account.name = item.name;
                this.account.profile = item.account_profile;
                $('#activatePartner').modal('show');
            },
            loadRoles: function() {
				let uri = '/admin/roles/roles';
				axios.get(uri).then(response => {
					this.roles = response.data;
				});
			},
        },
        created: function() {
            this.loadRegisteredPartners();
            this.loadRoles();

            Fire.$on('Refresh-datas', () => {
                this.loadRegisteredPartners();
            });
        }
    }
</script>

<style lang="scss" scoped>
    .bg-light-danger{
        background-color: rgb(255, 244, 244);
    }
    .cursor-pointer {
        cursor: pointer;
    }
</style>