<template>
	<div>
		<div class="row starter-main pt-5" style="background-color: #f8f9fa !important;">
			<div class="col-xl-12 col-sm-12 col-lg-12">
				<div class="card pt-4 bg-primary align-middle">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-1 col-sm-12"></div>
							<div class="col-lg-10 col-sm-12">
								<ol class="breadcrumb mt-0 mt-sm-0 mt-md-3 mt-lg-3 mt-xl-3 mb-0 p-b-0 pl-5" style="overflow:auto; white-space: nowrap; text-overflow: ellipsis;">
									<li class="breadcrumb-item"><a :href="'/'" class="text-white" data-bs-original-title="" title=""><i class="fa fa-home"></i></a></li>
									<!-- <li class="breadcrumb-item f-w-800">Partenaires</li> -->
									<li class="breadcrumb-item active text-white f-w-800">
										<span v-if="showPaymentSection">Validation de la commande</span>
										<span v-if="showCongratSection">Merci pour votre commande</span>
										<span v-if="showCheckoutForm" style="font-weight: 700 !important;">Formulaire de commande</span>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row pt-5 pb-5" style="background-color: #f8f9fa !important;">
			<div class="col-lg-1 xl-2 col-sm-12"></div>
		    <div class="col-lg-10 xl-8 col-sm-12">
		        <div class="card" :class="!showCongratSection ? 'shadow-sm' : ''" style="background-color: #f8f9fa !important; border: 1px solid #ececec; border-radius: 15px;">
					<div class="card-header" style="background-color: #f8f9fa !important; border-top-left-radius: 15px; border-top-right-radius: 15px;">
						<h5 style="text-transform: none;" class="text-center">
							<span v-if="showPaymentSection">Validation de commande</span>
							<span v-if="showCongratSection" style="font-size: 30px; font-weight: 800;" class="text-success"><i class="fa fa-check" style="font-size: 40px;"></i> Commande Validee</span>
							<span v-if="showCheckoutForm" style="font-weight: 700 !important;"> Formulaire de commande</span>
						</h5>
					</div>
			        <div class="card-body"  v-if="showCheckoutForm">  
			            <form @submit.prevent="convenientAction">
				            <div class="row">
								<div class="col-xl-6 col-sm-12">
									<div class="card b-r-10">
										<div class="card-body">
											<div class="row">
												<div class="mb-3">
													<label class="form-check-label" for="gridCheck">Avez-vous un compte?</label>
												</div>
												<div class="form-group mb-4 m-checkbox-inline mb-0 custom-radio-ml">
													<div class="radio radio-primary">
														<input id="radioinline1" type="radio" v-model="form.have_account" :value="true">
														<label class="mb-0" for="radioinline1">Oui, j'en ai</label>
													</div>
													<div class="radio radio-warning">
														<input id="radioinline2" type="radio" v-model="form.have_account" :value="false">
														<label class="mb-0" for="radioinline2">Non, pas encore</label>
													</div>
												</div>
											</div>
										<div class="row" v-if="!form.have_account">
											<div class="mb-3 col-sm-6">
												<label for="inputEmail4">Nom<span class="text-danger">*</span></label>
												<input class="form-control" id="inputEmail4" type="text" v-model="form.name" placeholder="Entrez votre nom">
												<div class="text-danger" v-if="form.errors.has('name') && !form.have_account" v-html="form.errors.get('name').replace('validation.required', 'Le champs <b>Nom</b> est requis')" />
											</div>
											<div class="mb-3 col-sm-6">
												<label for="inputPassword4">Prenom<span class="text-danger">*</span></label>
												<input class="form-control" id="inputPassword4" type="text" v-model="form.last_name" placeholder="Entrez votre prenom">
												<div class="text-danger" v-if="form.errors.has('last_name') && !form.have_account" v-html="form.errors.get('last_name').replace('last_name', '<b>Prenom</b>')" />
											</div>
										</div>
										<div class="row" v-else>
											<div class="mb-3 col-sm-12">
												<label for="inputEmail4">Nom d'utilisateur<span class="text-danger">*</span></label>
												<input class="form-control" id="inputEmail4" type="text" v-model="form.username" placeholder="Entrez votre nom d'utilisateur">
												<div class="text-danger" v-if="form.errors.has('username')" v-html="form.errors.get('username').replace('validation.required', 'Le champs <b>Nom d\'utilisateur </b> est requis')" />
											</div>
											<div class="mb-3 col-sm-12">
												<label for="inputPassword4">Mot de passe<span class="text-danger">*</span></label>
												<input class="form-control" id="inputPassword4" type="password" v-model="form.password" placeholder="Entrez votre mot de passe">
												<div class="text-danger" v-if="form.errors.has('password')" v-html="form.errors.get('password').replace('validation.required', 'Le champs <b>Mot de passe</b> est requis')" />
											</div>
										</div>
										<div class="row" v-if="!form.have_account">
											<div class="mb-3 col-sm-12">
												<label class="form-label" for="inputEmail5">Telephone<span class="text-danger">*</span></label>
												<vue-tel-input v-model="form.phone_number" mode="international" id="whatsapp-phone-number" styleClasses="form-control shadow-sm"></vue-tel-input>
												<div class="text-danger" v-if="form.errors.has('phone_number') && !form.have_account" v-html="form.errors.get('phone_number').replace('validation.required', 'Le champs <b>Telephone</b> est requis')" />
											</div>
										</div>
										
										<div class="row" v-if="!form.have_account">
											<div class="mb-3 col-sm-6">
												<label for="inputState">Ville<span class="text-danger">*</span></label>
												<select class="form-select digits" id="inputState" v-model="form.city_id" @change="loadZones">
													<option value="" selected>De quelle ville etes-vous?...</option>
													<option v-for="(city, index) in cities" :key="index" :value="city.id">{{ city.name }}</option>
												</select>
												<div class="text-danger" v-if="form.errors.has('city_id') && !form.have_account" v-html="form.errors.get('city_id').replace('validation.required', 'Le champs <b>Ville</b> est requis')" />
											</div>
											<div class="mb-3 col-sm-6">
												<label for="inputState">Quartiers<span class="text-danger">*</span></label>
												<select class="form-select digits" id="inputState" v-model="form.zone_id">
													<option value="" selected>Quel est votre quartier...</option>
													<option v-for="(zone, index) in zones" :key="index" :value="zone.id">{{ zone.name }}</option>
												</select>
												<div class="text-danger" v-if="form.errors.has('zone_id')" v-html="form.errors.get('zone_id').replace('validation.required', 'Le champs <b>Quartiers</b> est requis')" />
											</div>
										</div>
										<div class="row" v-if="!form.have_account">
											<div class="mb-3">
												<label class="form-check-label" for="gridCheck">Voulez-vous creer un compte?</label>
											</div>
											<div class="form-group mb-4 m-checkbox-inline mb-0 custom-radio-ml">
												<div class="radio radio-primary">
													<input id="radioinline3" type="radio" v-model="form.create_account" :value="true">
													<label class="mb-0" for="radioinline3">Oui</label>
												</div>
												<div class="radio radio-warning">
													<input id="radioinline4" type="radio" v-model="form.create_account" :value="false">
													<label class="mb-0" for="radioinline4">Non</label>
												</div>
											</div>
											<div class="row" v-if="form.create_account">
												<div class="mb-3 col-sm-12">
													<label for="inputEmail4">Nom d'utilisateur<span class="text-danger">*</span></label>
													<input class="form-control" id="inputEmail4" type="text" v-model="form.username" placeholder="Entrez votre nom d'utilisateur">
													<div class="text-danger" v-if="form.errors.has('username')" v-html="form.errors.get('username').replace('validation.required', 'Le champs <b>Nom d\'utilisateur </b> est requis')" />
												</div>
												<div class="mb-3 col-sm-12">
													<label for="inputPassword4">Mot de passe<span class="text-danger">*</span></label>
													<input class="form-control" id="inputPassword4" type="password" v-model="form.password" placeholder="Entrez votre mot de passe">
													<div class="text-danger" v-if="form.errors.has('password')" v-html="form.errors.get('password').replace('validation.required', 'Le champs <b>Mot de passe</b> est requis')" />
												</div>
											</div>
										</div>
										<div class="row bg-light p-3 b-r-5 b-primary border-2" v-if="cartContainUnderPrescriptionProduct">
											<label class="mb-2 text-dark" for="">Ordonnance <span class="text-danger">*</span></label>
											<div class=" mb-3 col-lg-12 col-sm-12 col-xl-12">
												<span class="text-dark" style="font-size: 12px;">Votre panier d'achat contient des produits pharmaceutiques qui ne sont delivrable que sous prescription medicale. <b class="text-primary">Inserez le scan ou la photo de votre ordonnance</b> dans le champs ci-dessous</span>
												<picture-input 
													ref="pictureInput"
													width="600" 
													height="200" 
													margin="16" 
													accept="image/jpeg,image/png" 
													size="5"
													:removable="true"
													:zIndex="0"
													button-class="btn btn-outline-warning"
													:custom-strings="{
													upload: '<h1>Ordonnance!</h1>',
													drag: '<span class= text-dark style=font-size:13px;>Selectionnez le fichier 😺</span>',
													change: 'Changer la Photo',
														remove: 'Supprimer la Photo',
													}"
													@change="onChange">
												</picture-input>
												<div class="text-danger" v-if="form.errors.has('prescription')" v-html="form.errors.get('prescription').replace('validation.required', 'Le champs <b>Ordonnance</b> est requis')" />
											</div>
										</div>
										
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-sm-12">
									<div class="card shadow-sm" style="background-color: #f8f9fa; border-radius: 10px;">
										<div class="card-body">
											<div class="checkout-details">
											<div class="order-box">
												<div class="title-box">
												<div class="checkbox-title">
													<h4 class="mb-0">Produit </h4><span>Total</span>
												</div>
												</div>
												<ul class="qty">
												<li v-for="(item, index) in carts" :key="index">
													<b v-if="item.have_variation">{{ item.combinationName }} × {{ item.quantity }}</b> 
													<b v-else>{{ item.product }} × {{ item.quantity }}</b > 

													<span v-if="item.have_variation">{{ item.combinantionPrice * item.quantity }} Fcfa</span>
													<span v-else>{{ item.price * item.quantity }} Fcfa</span></li>
												</ul>
												<ul class="sub-total total">
												<li>Totale Generale <span class="count f-w-900 text-warning">{{ cartTotalAmount }} FCFA</span></li>
												</ul>
												<div class="animate-chk">
												<div class="row">
													<div class="col">

															<div class="form-group d-block m-t-15 custom-radio-ml">
																<div class="radio radio-primary">
																	<input id="radio1" type="radio" v-model="form.payment_method" value="OM">
																	<label for="radio1">
																		Orange Money
																		<!-- <img :src="BaseUrl+'OM1.png'" width="120" height="55"alt="" style="border-radius: 5px;"> -->
																	</label>
																	
																</div>
																<div class="radio radio-primary">
																	<input id="radio3" type="radio" v-model="form.payment_method" value="MM">
																	<label for="radio3">
																		Moov Money
																		 <!-- <img :src="BaseUrl+'OM1.png'" width="120" height="55"alt="" style="border-radius: 5px;"> -->
																	</label>
																</div>
																<div class="radio radio-primary">
																	<input id="radio4" type="radio" v-model="form.payment_method" value="CM" checked="">
																	<label for="radio4">Coris Money</label>
																</div>
																<div class="radio radio-primary">
																	<input id="radio5" type="radio" v-model="form.payment_method" value="SM" checked="">
																	<label for="radio5">Sank Money</label>
																</div>
															</div>
															<div class="text-danger" v-if="form.errors.has('payment_method')" v-html="form.errors.get('payment_method').replace('validation.required', 'Le champs <b>Moyen de paiement</b> est requis')" />
													</div>
												</div>
												</div>
												<div class="order-place mt-3"><button class="btn btn-primary" type="submit" :disabled="disabled">Valider la commande  </button></div>
											</div>
											</div>
										</div>
									</div>
								</div>
							</div>
		              	</form>
			        </div>
					<div class="card-body" v-if="showPaymentSection">
						<form @submit.prevent="checkout">
							<div class="row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<div class="list-group mb-3">
										<a class="list-group-item list-group-item-action active fw-bold fs-6" href="javascript:void(0)" data-bs-original-title="" title="">Vos informations</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Nom complet:</strong> {{ form.client.name }} {{ form.client.last_name }}</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Numero de telephone:</strong> {{ form.client.phone_number }}</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Ville:</strong> {{ form.client.city.name }}</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Zone / Quartier:</strong> {{ form.client.zone.name }}</a>
									</div>
									<div class="card" style="background-color: whitesmoke;">
										<div class="card-body">
											<div class="checkout-details">
												<div class="order-box">
													<div class="title-box">
													<div class="checkbox-title">
														<h4 class="mb-0">Produit </h4><span>Total</span>
													</div>
													</div>
													<ul class="qty">
													<li v-for="(item, index) in carts" :key="index">
														<b v-if="item.have_variation">{{ item.combinationName }} × {{ item.quantity }}</b> 
														<b v-else>{{ item.product }} × {{ item.quantity }}</b > 

														<span v-if="item.have_variation">{{ item.combinantionPrice * item.quantity }} Fcfa</span>
														<span v-else>{{ item.price * item.quantity }} Fcfa</span></li>
													</ul>
													<ul class="sub-total total">
													<li>Totale Generale <span class="count f-w-900 text-warning">{{ cartTotalAmount }} FCFA</span></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<div class="card bg-white">
										<div class="card-body">
											<div class="text-center">
												<img class="mb-3" :src="BaseUrl+'orangemoney.png'" width="30%" height="40%" alt="">
											</div>
											<div class="form-group mb-4 mx-md-5 mx-lg-5">
												<div class="form-floating shadow-sm">
													<input type="tel" id="customer-msisdn"  v-mask="'##-##-##-##'" v-model="form.customerMsisdn" class="form-control" placeholder="Entrer le numero pour le paiement">
													<label for="customer-msisdn" class="form-label">Numero Orange Money <span class="text-danger" style="font-size: 10px;">(Obligatoire) <sup>*</sup></span></label>
												</div>
												<div class="text-danger" v-if="form.errors.has('customerMsisdn')" v-html="form.errors.get('customerMsisdn').replace('validation.required', 'Le <b>Numero Orange Money</b> est requis')" />
												<span class="text-danger fw-semibold text-sm" style="font-size: 12px;" v-if="form.customerMsisdn && !isOrangePrefix">Ce numero n'est pas un numero Orange, veuillez entrer un bon numero</span>
											</div>
											<div class="form-group mx-md-5 mx-lg-5">
												<span class="content-heading text-primary">Generer le code OTP</span>
												<p class="text-muted">
													Pour la finalisation du processus de paiement, vous devez generer un code OTP. pour generer le code OTP, vous devez composer le code suivant:
													<p class="fs-5 fw-bold text-warning">*144*4*6*montant total de votre commande#</p>
													<p class="text-muted">Vous recevrez un code par SMS sur votre portable, inscrivez ce code dans le champs ci-dessous et cliquer sur payer pour finaliser cotre commande.</p>
												</p>
											</div>

											<div class="form-group mx-md-5 mx-lg-5">
												<div class="form-floating shadow-sm">
													<input type="text" inputmode="numeric" v-model="form.otp" class="form-control" placeholder="Entrer le code OTP ici....">
													<label for="" class="form-label">Taper le code OTP <span class="text-danger" style="font-size: 10px;">(Obligatoire) <sup>*</sup></span></label>
												</div>
												<div class="text-danger" v-if="form.errors.has('otp')" v-html="form.errors.get('otp').replace('validation.required', 'Le <b>Code OTP</b> est requis')" />
											</div>
										</div>
										<div class="card-footer text-center p-3">
											<button class="btn btn-pill btn-outline-warning-2x mb-2 mb-sm-2 mb-md-0 mb-lg-0" type="button" data-bs-original-title="" title="Retour au panier" @click="showPaymentSection == !showPaymentSection">Retour au panier</button>
											<button class="btn btn-pill btn-outline-primary-2x" type="submit" data-bs-original-title="" title="Payer maintenant">Payer maintenant</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
		        </div>
				<section v-if="showCongratSection" class="congrat-section">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
						<div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
							<div class="row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<p class="text-muted text-success mb-2">Bravo! Votre commande a bien ete enregistree. trouver ci-dessous les informations de votre commande. Restez a l'ecoute pour la suite de procedure. Nous vous contacterons pour la livraison de votre commande.</p>
									<div class="list-group mb-3">
										<a class="list-group-item list-group-item-action bg-success b-success active fw-bold fs-6" href="javascript:void(0)" data-bs-original-title="" title="">Vos informations</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Nom complet:</strong> {{ customer.name }} {{ customer.last_name }}</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Numero de telephone:</strong> {{ customer.phone_number }}</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Ville:</strong> {{ customer.city.name }}</a>
										<a class="list-group-item list-group-item-action" href="javascript:void(0)" data-bs-original-title="" title=""><strong>Zone / Quartier:</strong> {{ customer.zone.name }}</a>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<div class="card" style="background-color: whitesmoke;">
										<div class="card-body">
											<div class="checkout-details">
												<div class="order-box">
													<div class="title-box">
													<div class="checkbox-title">
														<h4 class="mb-0">Produit </h4><span>Total</span>
													</div>
													</div>
													<ul class="qty">
													<li v-for="(item, index) in items" :key="index">
														<b v-if="item.have_variation">{{ item.combinationName }} × {{ item.quantity }}</b> 
														<b v-else>{{ item.product }} × {{ item.quantity }}</b > 

														<span v-if="item.have_variation">{{ item.combinantionPrice * item.quantity }} Fcfa</span>
														<span v-else>{{ item.price * item.quantity }} Fcfa</span></li>
													</ul>
													<ul class="sub-total total">
													<li>Totale Generale <span class="count f-w-900 text-success">{{ order.order_amount }} FCFA</span></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
					</div>
				</section>
		    </div>
    	</div>
		<div class="modal fade" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<!-- <div class="modal-header">
						<h5 class="modal-title">
							<img class="mb-3" :src="BaseUrl+'orangemoney.png'" width="30%" height="40%" alt="Orange Money" v-if="form.payment_method == 'OM'">
							<img class="mb-3" :src="BaseUrl+'MM.png'" width="50" height="50" alt="Moov Money" v-if="form.payment_method == 'MM'">
							<img class="mb-3" :src="BaseUrl+'CM.png'" width="50" height="50" alt="Coris Money" v-if="form.payment_method == 'CM'">
							<img class="mb-3" :src="BaseUrl+'SM.png'" width="50" height="50" alt="Sank Money" v-if="form.payment_method == 'SM'">
							Procedure de paiement
						</h5>
						<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
					</div> -->
					<div class="modal-body">
						<p class="text-center">
							<img class="mb-3" :src="BaseUrl+'OM1.png'" width="30%" height="40%" alt="Orange Money" v-if="form.payment_method == 'OM'" style="margin-top: -15%; border-radius: 10px;">
							<img class="mb-3" :src="BaseUrl+'MM.png'" width="30%" height="40%" alt="Moov Money" v-if="form.payment_method == 'MM'" style="margin-top: -20%; border-radius: 10px;">
							<img class="mb-3" :src="BaseUrl+'CM.png'" width="30%" height="40%" alt="Coris Money" v-if="form.payment_method == 'CM'" style="margin-top: -15%; border-radius: 10px;">
							<img class="mb-3" :src="BaseUrl+'SM.png'" width="30%" height="40%" alt="Sank Money" v-if="form.payment_method == 'SM'" style="margin-top: -15%; border-radius: 10px;">
						</p>
						<div class="form-group mb-4 mx-md-5 mx-lg-5" v-if="form.payment_method == 'OM'">
							<div class="form-floating shadow-sm">
								<input type="tel" id="customer-msisdn"  v-mask="'##-##-##-##'" v-model="form.customerMsisdn" class="form-control" placeholder="Entrer le numero pour le paiement">
								<label for="customer-msisdn" class="form-label">Numero Orange Money <span class="text-danger" style="font-size: 10px;">(Obligatoire) <sup>*</sup></span></label>
							</div>
							<div class="text-danger" v-if="form.errors.has('customerMsisdn')" v-html="form.errors.get('customerMsisdn').replace('validation.required', 'Le <b>Numero Orange Money</b> est requis')" />
							<span class="text-danger fw-semibold text-sm" style="font-size: 12px;" v-if="form.customerMsisdn && !isOrangePrefix">Ce numero n'est pas un numero Orange, veuillez entrer un bon numero</span>
						</div>
						<div class="form-group mb-4 mx-md-5 mx-lg-5" v-if="form.payment_method == 'MM'">
							<div class="form-floating shadow-sm">
								<input type="tel" id="customer-msisdn"  v-mask="'##-##-##-##'" v-model="form.customerMsisdn" class="form-control" placeholder="Entrer le numero pour le paiement">
								<label for="customer-msisdn" class="form-label">Numero Moov Money <span class="text-danger" style="font-size: 10px;">(Obligatoire) <sup>*</sup></span></label>
							</div>
							<div class="text-danger" v-if="form.errors.has('customerMsisdn')" v-html="form.errors.get('customerMsisdn').replace('validation.required', 'Le <b>Numero Moov Money</b> est requis')" />
							<span class="text-danger fw-semibold text-sm" style="font-size: 12px;" v-if="form.customerMsisdn && !isMoovPrefix">Ce numero n'est pas un numero Moov, veuillez entrer un bon numero</span>
						</div>
						<div class="form-group mb-4 mx-md-5 mx-lg-5" v-if="form.payment_method == 'CM' || form.payment_method == 'SM'">
							<div class="form-floating shadow-sm">
								<input type="tel" id="customer-msisdn"  v-mask="'##-##-##-##'" v-model="form.customerMsisdn" class="form-control" placeholder="Entrer le numero pour le paiement">
								<label for="customer-msisdn" class="form-label">Numero du compte<span class="text-danger" style="font-size: 10px;">(Obligatoire) <sup>*</sup></span></label>
							</div>
							<div class="text-danger" v-if="form.errors.has('customerMsisdn')" v-html="form.errors.get('customerMsisdn').replace('validation.required', 'Le <b>Numero du compte</b> est requis')" />
						</div>
						<div class="form-group mx-md-5 mx-lg-5" v-if="showResultMessage">
							<span class="content-heading text-primary">Code OTP - suivez les instructions</span>
							<p class="text-muted" v-html="resultMessage"></p>
						</div>

						<div class="form-group mx-md-5 mx-lg-5" v-if="showOtpInput">
							<div class="form-floating shadow-sm">
								<input type="text" inputmode="numeric" v-model="form.otp" class="form-control" placeholder="Entrer le code OTP ici....">
								<label for="" class="form-label">Taper le code OTP <span class="text-danger" style="font-size: 10px;">(Obligatoire) <sup>*</sup></span></label>
							</div>
							<div class="text-danger" v-if="form.errors.has('otp')" v-html="form.errors.get('otp').replace('validation.required', 'Le <b>Code OTP</b> est requis')" />
						</div>
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<button class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="" @click="abandonPayment()">Annuler</button>
						<button class="btn btn-primary" type="button" data-bs-original-title="" title="" v-if="!showResultMessage" @click="createCharge()">Continuer</button>
						<button class="btn btn-primary" type="button" data-bs-original-title="" title="" v-if="showResultMessage && showOtpInput" @click="checkout()">Procedez au paiement</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import LoadCart from '../loadCart.js';
import Placement from '../placement.js';
import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
import PictureInput from 'vue-picture-input';
import EventBus from '../details/EventBus';


export default {
	mixins: [LoadCart, Placement],
	components:{
		Button, HasError, AlertError, PictureInput
	},
	data: function() {
		return  {
			BaseUrl: Laravel.baseUrl,
			form: new Form({
				have_account:false,
				create_account: false,
				name:'',
				last_name:'',
				phone_number:'',
				city_id:'',
				zone_id:'',
				payment_method:'',
				order_amount: '',
				username:'',
				password:'',
				need_prescription:false,
				prescription: '',
				carts:[],
				customerMsisdn:'',
				otp:'',
				showPaymentSection:false,
				showCheckoutForm: false,
				// charge_reference: '',
				client:[],
			}),
			order:[],
			items:[],
			customer:[],
			color: '#168EEA',
			showPaymentSection:false,
			showCongratSection:false,
			showResultMessage: false,
			resultMessage: '',
			showOtpInput: false,
			showCheckoutForm: true,
			orangePrefixes:["05", "06", "07", "54", "55", "56", "57", "64", "65", "66", "67", "74", "75", "76", "77"],
			moovPrefixes: ["70", "71", "72", "73", "60", "61", "62", "63", "50", "51", "52", "53", "01", "02", "03"]
		}
	},
	computed: {
		needPayment: function() {
			return (this.form.payment_method == 'OM' || this.form.payment_method == 'MM' || this.form.payment_method == 'CM' || this.form.payment_method == 'SM')? true : false;
		},
		isOrangePrefix: function() {
			let array = this.form.customerMsisdn.split("-");
			return (this.orangePrefixes.includes(array[0]));
		},
		isMoovPrefix: function() {
			let array = this.form.customerMsisdn.split("-");
			return (this.moovPrefixes.includes(array[0]));
		}
	},
	methods: {
		convenientAction: function() {
			(this.form.payment_method === '' || this.needPayment)? this.checkForm() : this.checkout();
		},
		checkForm: function() {
			const loading = this.$vs.loading({type:'square', color: this.color, text: 'Validation en cours'});
			let uri = '/api/check-order-data';
			this.form.need_prescription = this.cartContainUnderPrescriptionProduct;
			this.form.order_amount = this.cartTotalAmount;
			this.form.carts = this.carts;

			this.form.post(uri).then(response => {
				loading.close();
				
				if(!response.data.success) {
					Swal.fire({
						title:response.data.title,
						text: response.data.message,
						icon: (response.data.success)? 'success':'error',
						button: "D'accord!",
					});	
				}

				// this.showPaymentSection = response.data.success;
				// this.showCheckoutForm = !response.data.success;
				// this.showCheckoutForm = !response.data.success;
				(response.data.success)? this.form.client = response.data.client : null;
				$("#exampleModalCenter").modal('show');

			}).catch(err => {
				loading.close();
				console.log(err);
			});
		},

		createCharge: function() {
			const loading = this.$vs.loading({type:'square', color: this.color, text: 'Validation en cours'});
			let uri = '/api/create-charge';

			this.form.post(uri).then(response => {

				if(response.data.success) {
					this.showResultMessage = true;
					this.showOtpInput = true;
					this.resultMessage = response.data.response.data.display_text;
					this.form.charge_reference = response.data.response.data.reference;
				}

				loading.close();
			}).catch(err => {
				loading.close();
				console.log(err);
			});
		},
		abandonPayment: function() {
			this.showResultMessage = false;
			this.showOtpInput = false;
			this.form.customerMsisdn = '';
		},
		checkout: function() {
			// alert('checking out');
			const loading = this.$vs.loading({type:'square', color: this.color, text: 'Validation en cours'});
			let uri = '/api/save-order';
			// this.form.need_prescription = this.cartContainUnderPrescriptionProduct;
			// this.form.order_amount = this.cartTotalAmount;
			// this.form.carts = this.carts;
			this.form.post(uri).then(response => {
				
				loading.close();
				if(response.data.success) {
					this.showCongratSection = true;
					this.showPaymentSection = false;
					this.showCheckoutForm = false;
					this.carts = [];
					this.order = response.data.order;
					this.items = response.data.items;
					this.customer = response.data.customer;
					EventBus.emit('empty-cart');
					$("#exampleModalCenter").modal('hide');
				}

				Swal.fire({
					title:response.data.title,
				  text: response.data.message,
				  icon: (response.data.success)? 'success':'error',
				  button: "D'accord!",
				});

			}).catch(err => {
				loading.close();
				 console.log(err);
				 // 	Swal.fire({
					// 	title:response.data.title,
					//   text: response.data.message,
					//   icon: (response.data.success)? 'success':'error',
					// });
			});
		},
		onChange (image) {
			console.log('New picture selected!')
			if (image) {
				console.log('Picture loaded.')
				this.form.prescription = image
			} else {
				console.log('FileReader API not supported: use the <form>, Luke!')
			}
		},
	},

	created: function() {},

	mounted: function() {}

}

</script>