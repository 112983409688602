<template>
	<div>
		<div class="">
			<div class="card b-r-5">
				<div class="card-header pb-0 align-middle b-b-light">
					<div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<select v-model="filter.per_page" class="form-select digits" @change="loadOrderItems">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
						<div class="col-sm-12 col-lg-1 col-md-1"></div>
						<div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<!-- <label for="name">Points de vente concerne</label> -->
		                    	<multiselect v-model="filter.account_id" :options="partners" :hide-selected="false" placeholder="Points de vente" label="name" track-by="id" @input="loadOrderItems"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-2 col-md-2">
							<div class="form-group">
		                    	<!-- <label for="name">Etat Commande</label> -->
		                    	<multiselect v-model="filter.order_state" :options="oStates" :hide-selected="false" placeholder="Etats" label="value" track-by="key" @input="loadOrderItems"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-2 col-md-2">
							<div class="form-group">
		                    	<!-- <label for="name">Statut Commande</label> -->
		                    	<multiselect v-model="filter.order_status" :options="oStatus" :hide-selected="false" placeholder="Statuts" label="value" track-by="key" @input="loadOrderItems"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<!-- <label for="name">Moyen de paiement</label> -->
		                    	<multiselect v-model="filter.payment_method" :options="pMethods" :hide-selected="false" placeholder="Moyens de paiement" label="value" track-by="key" @input="loadOrderItems"></multiselect>
		                    </div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-mobile-responsive table-mobile-sided">
							<thead>
								<tr>
									<th>#</th>
									<!-- <th>Image</th> -->
									<th>Produit</th>
									<th>Client</th>
									<th>Qte</th>
									<th>P.U</th>
									<!-- <th>P.T</th> -->
									<th>Methode paiement</th>
									<th>Etat</th>
									<th>Statut</th>
									<th>Date</th>
									<!-- <th>...</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in orderItems.data" :key="index">
										
									<td class="align-middle" data-content="Id">{{ index+1 }}</td>
									<td class="align-middle" data-content="Produit">
										<div class="media" style="vertical-align: middle;">
											<img class="img-fluid d-none d-sm-none d-md-inline d-lg-inline d-xl-inline" :src="baseUrl+'medias/combination_image/frontsize/187x187/'+item.image" alt="" width="40" height="40" style="border-radius: 100%;" title="" v-if="item.product_combination_id !== null">
											<img class="img-fluid mr-1 d-none d-sm-none d-md-inline d-lg-inline d-xl-inline" :src="baseUrl+'medias/partner_product_image/frontsize/187x187/'+item.image" alt="" width="40" style="border-radius: 100%;" height="40" title="" v-else>
                                    		<div class="media-body">
                                    			<a href="#" class="text-dark">
                                    				<span style="margin-left:10px; vertical-align: middle;">{{ item.name }}</span>
                                    			</a>
                                    		</div>
                                  		</div>
									</td>
									<td class="align-middle" data-content="Client">{{ item.client_name }} {{ item.client_last_name }}</td>
									<!-- <td class="align-middle"> {{ item.name }} </td> -->
									<td class="align-middle" data-content="Qte">{{ item.quantity }}</td>
									<td class="align-middle" data-content="P.Unit">{{ item.unit_price }} F</td>
									<!-- <td class="align-middle">{{ item.quantity * item.unit_price }} F</td> -->
									<td class="align-middle" data-content="Methode paiement" v-html="checkMethodName(item.payment_method)"></td>
									<td class="align-middle" data-content="Etat" v-html="checkStateName(item.order_state)"></td>
									<td class="align-middle" data-content="Status" v-html="checkStatusName(item.order_status)"></td>
									<td class="align-middle" data-content="Date">{{ item.created_at | moment("DD-MM-YYYY") }}</td>
									<!-- <td class="align-middle" data-content="Action">
										<span style="cursor: pointer;" class="m-r-1" @click="showModal('#order-item', item)">
											<feather type="shopping-cart" size="13"></feather>
										</span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
                    						<feather type="trash-2" size="13"></feather>
                    					</span>
									</td> -->
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="10" class="border-0">
										<div class="mt-3">
											<pagination :data="orderItems" :limit="1" :size="small" @pagination-change-page="loadOrderItems">
												<span slot="prev-nav">&lt; Precedent</span>
												<span slot="next-nav">Suivant &gt;</span>
											</pagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	import OrderMethods from '../mixins/orderMethods.js';
	import FormatTHIS from '../mixins/formatter.js';

	export default {
		mixins:[OrderMethods, FormatTHIS],
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				baseUrl: Laravel.baseUrl,
				filter: new Form({
					account_id:'',
					order_state:'',
					order_status:'',
					payment_method: '',
					per_page:10
				}),
				oStates:[
					{key: 'paid', value: 'Payee'},
					{key: 'unpaid', value: 'Impayee'},
				],
				oStatus:[
					{key: 'pending', value: 'En attente'},
					{key: 'ongoing', value: 'En cours'},
					{key: 'closed', value: 'Termine'},
				],
				pMethods:[
					{key: 'OM', value: 'Orange Money'},
					{key: 'MM', value: 'Moov Money'},
					{key: 'CM', value: 'Coris Money'},
					{key: 'SM', value: 'Sank Money'}
				],
				pages: [1,5,10,20,30,40,50,60,70,80,90,100],
				partners:[],
				orderItems:{},
				color: '#168EEA',
			}
		},
		methods: {
			loadOrderItems: function(page = 1) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement'});
				let uri = "/admin/orders/order-items-filtered?page="+page;

				this.filter.post(uri).then(response => {
					loading.close();
					this.orderItems = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadPartners: function() {
				let uri = "/admin/partners/activated-sellpoints-partners";

				axios.get(uri).then(response => {
					this.partners = response.data;
				}).catch(err => {
					console.log(err);
				});
			},
		},
		created: function() {
			this.loadPartners();
			this.loadOrderItems();
		}
	}
</script>