<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="form-group">
                            <label for="perpage"></label>
                            <select v-model="filter.per_page" class="form-select digits" id="perpage" @change="loadAccounts">
                                <option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
                            </select>
                        </div>
                        <div>
                            <a href="#" class="btn btn-light btn-sm" data-bs-toggle="modal" data-bs-target="#newTelegramAcc"><span class="fa fa-plus mx-1"></span>Ajouter
                            </a>
                        </div>
						<!-- <div class="col-sm-12 col-lg-1 col-md-1">
							
						</div>
						<div class="col-sm-12 col-lg-2 col-md-2 align-middle">
                            
                        </div> -->
					</div>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom Complet</th>
                                    <th>Profil</th>
                                    <th class="bg-light">Compte Telegram</th>
                                    <th>Cree le</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in accounts.data" :key="index">
                                    <td>{{ index+1 }}</td>
                                    <td><span>{{ item.name }}</span></td>
                                    <td>{{ profileLang(item.profile) }}</td>
                                    <td>
                                        <span class="span badge rounded-pill pill-badge-primary" v-if="item.telegram_id">Id: {{ item.telegram_id }}</span>
                                        <span v-if="item.telegram_name"><b>Nom</b>: {{ item.telegram_name }}</span>
                                        <span v-if="item.telegram_number"><b>Numero</b>: {{ item.telegram_number }}</span>
                                    </td>
                                    <td>{{ item.created_at | moment("DD-MM-YYYY H:m")}}</td>
                                    <td class="text-center align-middle">
                                        <span>
                                            <feather type="trash-2" class="text-primary cursor-pointer" size="13" @click="deleteTelegramAcc(item)"></feather>
                                        </span>
                                        <span>
                                            <feather type="edit" class="text-warning cursor-pointer" size="13" @click="showModal(item)"></feather>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="7">
                                        <pagination :data="accounts" @pagination-change-page="loadAccounts">
                                            <span slot="prev-nav">&lt; Prec</span>
                                            <span slot="next-nav">Suiv &gt;</span>
                                        </pagination>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="newTelegramAcc" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background-color:'';">
                <form @submit.prevent="newTelegramAcc">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Ajouter un compte telegram</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>
                    <div class="modal-body">
                        <div class="mt-2 mb-2" style="border-radius: 0px; padding: 2%;">
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Selectionner un utilisateur</label><br>
                                    <multiselect v-model="telegramcc.user" :options="users" :hide-selected="false" placeholder="Tous les utilisateurs" label="name" track-by="key"></multiselect>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Entrer l'Id du compte telegram</label><br>
                                    <input type="text" v-model="telegramcc.telegram_id" class="form-control" placeholder="Entrer l'Id du compte Telegram">
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="form-group">
                                    <label for="">Nom Telegram</label><br>
                                    <input type="text" v-model="telegramcc.telegram_name" class="form-control" placeholder="Entrer le nom">
                                </div>
                                <div class="form-group">
                                    <label for="">Numero Telegram</label><br>
                                    <input type="text" v-model="telegramcc.telegram_number" class="form-control" placeholder="Entrer le numero">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">Close</button>
                        <button class="btn btn-primary" type="submit" data-bs-original-title="" title="">Enregistrer</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
        <div class="modal fade" id="editTelegramAcc" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background-color:'';">
                <form @submit.prevent="updateTelegramAcc">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Modifier un compte telegram</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>
                    <div class="modal-body">
                        <div class="mt-2 mb-2" style="border-radius: 0px; padding: 2%;">
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Selectionner un utilisateur</label><br>
                                    <multiselect v-model="telegramcc.user" :options="users" :hide-selected="false" disabled placeholder="Tous les utilisateurs" label="name" track-by="key"></multiselect>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group">
                                    <label for="">Id du compte Telegram</label><br>
                                    <input type="text" v-model="telegramcc.telegram_id" class="form-control">
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="form-group">
                                    <label for="">Nom Telegram</label><br>
                                    <input type="text" v-model="telegramcc.telegram_name" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label for="">Numero Telegram</label><br>
                                    <input type="text" v-model="telegramcc.telegram_number" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">Close</button>
                        <button class="btn btn-primary" type="submit" data-bs-original-title="" title="">Mettre a jour</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
        <v-dialog />
    </div>
</template>
<script>
import axios from 'axios';
import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

    export default {
        components:{
            Button, HasError, AlertError
        },
        data: function() {
            return {
                filter: new Form({
                    per_page:10,
                }),

                accounts: [],
                users:[],
                telegramcc: new Form({
                    user: null,
                    telegram_id: null,
                    telegram_name: null,
                    telegram_number: null
                }),
                deleteAcc: new Form({
                    id: null,
                    user_id: null,
                    telegram_id: null,
                    telegram_name: null,
                    telegram_number: null
                }),
                pages: [1,5,10,20,30,40,50,60,70,80,90,100],
                account: [],
            }
        },
        methods: {
            profileLang: function(profile) {
                switch (profile) {
                    case 'Allogo-Office':
                            return 'Staff Allogo';
                        break;
                    case 'Deliver':
                            return 'Livreurs';
                        break;
                    case 'Restaurant':
                            return 'Restaurants';
                        break;
                    case 'Pharmacy':
                            return 'Pharmacies';
                        break;
                    case 'Supermarket':
                            return 'Supermarchés';
                        break;
                
                    default:
                        return '';
                        break;
                }
            },
            showModal: function(item) {
                this.telegramcc.user = this.users.find(x => x.id == item.user_id);
                this.telegramcc.telegram_id = item.telegram_id;
                this.telegramcc.telegram_name = item.telegram_name;
                this.telegramcc.telegram_number = item.telegram_number;
                this.account = item;
                
                $('#editTelegramAcc').modal('show');
            },
            loadUsers: function(){
                let uri="/admin/accounts/users/users";
                axios.get(uri).then(response => {
                    this.users = response.data;
                })
            },
            loadAccounts: function(page = 1) {
                let uri = "/admin/accounts/users/load-account-telegram?page="+page;
                this.filter.post(uri).then(response => {
                    this.accounts = response.data;
                }).catch(err => {
                    console.log(err);
                });
            },
            newTelegramAcc: function() {
                let uri = "/admin/accounts/users/new-telegram-account";
                this.telegramcc.post(uri).then(response => {
                    Fire.$emit('Refresh-datas');
                    if(response.data.success) {
                        $('#newTelegramAcc').modal('hide');
                        document.location.reload();
                    }
                    Toast.fire({
                        icon: response.data.success? 'success':'error',
                        title: response.data.message
                    });
                })
            },
            updateTelegramAcc: function() {
                let uri = "/admin/accounts/users/update-telegram-account";
                this.telegramcc.id = this.account.id;
                this.telegramcc.put(uri).then(response => {
                    Fire.$emit('Refresh-datas');
                    if(response.data.success) {
                        $('#editTelegramAcc').modal('hide');
                    }
                    Toast.fire({
                        icon: response.data.success? 'success':'error',
                        title: response.data.message
                    });
                })
            },
            deleteTelegramAcc: function(item) {
                let uri = "/admin/accounts/users/delete-telegram-account";
                this.deleteAcc.fill(item);
                console.log(this.deleteAcc);
                this.$modal.show('dialog', {
                    title: "Suppression de compte Telegram",
                    text: 'Êtes-vous certain de bien vouloir supprimer ce compte Telegram ?',
                    buttons: [
                        {
                            title: 'Non',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Oui',
                            handler: () => {
                                this.deleteAcc.delete(uri).then(response => {
                                    if(response.data.success) {
                                        Fire.$emit('Refresh-datas');
                                    }
                                    Toast.fire({
                                        icon: response.data.success? 'success':'error',
                                        title: response.data.message
                                    });
                                    this.$modal.hide('dialog')
                                })
                            }
                        }
                    ]
                });
            }
        },
        created: function() {
            this.loadAccounts();
            this.loadUsers();

            Fire.$on('Refresh-datas', () => {
                this.loadAccounts();
                this.loadUsers();
            });
        }
    }
</script>