<template>
	<div>
		<div class="row">
			<div class="card">
				<div class="card-body">
					<div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<!-- <label for="perpage"></label> -->
								<select v-model="filter.per_page" class="form-select digits" id="perpage" @change="loadInventories">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
						<div class="col-sm-12 col-md-3 col-lg-3">
							<div class="form-group">
		                    	<!-- <label for="name">Points de vente</label> -->
		                    	<multiselect v-model="filter.account" :options="partners" :hide-selected="false" placeholder="Tous les points" label="name" track-by="id" @input="loadInventories"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-md-3 col-lg-3">
							<div class="form-group">
		                    	<!-- <label for="name">Date</label> -->
								<date-picker v-model="filter.date" valueType="format" input-class="form-control form-control-lg" @change="loadInventories"></date-picker>
		                    	<!-- <input class="datepicker-here form-control form-control-lg" type="text" v-model="filter.date"> -->
		                    </div>
						</div>
						<div class="col-sm-12 col-md-5 col-lg-5"></div>
					</div>
					<div class="table-responsive">
						<table class="table table-bordered table-striped">
							<thead class="">
								<tr>
									<th>#</th>
									<th>Produits</th>
									<th class="text-center">Quantité en stock</th>
									<th class="text-center">Prix Unitaire</th>
									<th class="text-center">Prix Total</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in inventories" :key="index">
									<td>{{ index+1 }}</td>
									<td class="align-middle fw-bold">
										<span v-if="!item.have_variation" class="text-black">{{ item.product }}</span>
										<span v-else class="cursor-pointer text-primary">
											{{ item.product }} 
											<span class="badge badge rounded-pill pill-badge-info" @click="showModal('show-combination', item.id)">Variations</span>
										</span>
									</td>
									<td class="align-middle text-center"><span class="badge badge-secondary">{{ item.stock }}</span></td>
									<td class="align-middle fw-bold text-center">{{ formatTHIS(item.price, 2) }}</td>
									<td class="align-middle fw-bold text-center text-black">{{ formatTHIS((item.stock * item.price), 2) }}</td>
								</tr>
							</tbody>
							<tfoot class="bg-light">
								<tr>
									<td colspan="4" class="fw-bold">MONTANT TOTAL</td>
									<td class="fw-bold text-center align-middle">{{ totalInventory }} FCFA</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<!-- <div class="mt-3">
                    	<pagination :data="inventories" @pagination-change-page="loadInventories">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div> -->
				</div>
			</div>
		</div>
		<div class="modal fade" id="show-combination" tabindex="-1" aria-labelledby="exampleModalCenter" aria-hidden="true" style="display: none;">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<!-- <form @submit.prevent="UpdateCombinationSupply('edit-combination-product-inputs')"> -->
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Stock des Combinations</h5>
							<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
						</div>
						<div class="modal-body">
							<div class="table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th>#</th>
											<th>Combinaison</th>
											<th>Quantite en stock</th>
											<th>Prix Unitaire</th>
											<th>Prix Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in combinations" :key="index">
											<td class="align-middle"><b>{{ index }}</b></td>
											<td class="align-middle"><b>{{ item.combinationName }}</b></td>
											<td class="align-middle text-center"><span class="badge badge-info">{{ item.quantity }}</span></td>
											<td class="align-middle text-center">{{ formatTHIS(item.combinantionPrice, 2) }}</td>
											<td class="align-middle text-warning text-center">{{ formatTHIS((item.quantity * item.combinantionPrice), 2) }}</td>
										</tr>
									</tbody>
									<tfoot class="bg-light">
										<tr>
											<td class="f-w-900" colspan="4">MONTANT TOTAL</td>
											<td class="f-w-900 text-center align-middle">{{ totalCombination }} FCFA</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				<!-- </form> -->
			</div>
		</div>
	</div>
</template>
<script>

	import { OBJECTMEMBER_TYPES } from '@babel/types';
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	import Formater from '../mixins/formatter.js';

	import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

	export default {
		mixins:[Formater],
		components:{
			Button, HasError, AlertError, DatePicker
		},
		data: function() {
			return {
				filter: new Form({
					account:'',
					date:'',
					per_page:10
				}),
				inventdate:'',
				pages: [1,5,10,20,30,40,50,60,70,80,90,100],
				partners:[],
				inventories:[],
				combinations:[],
				color: '#168EEA',
			}
		},
		computed:{
			totalInventory: function(){
				let total = 0;
				Object.keys(this.inventories).forEach(key => {
					total += this.inventories[key].stock * this.inventories[key].price;
				});
				//return Math.ceil(total/this.inventories.length);
				return this.formatTHIS(total, 2);
			},
			totalCombination: function() {
				let total = 0;
				Object.keys(this.combinations).forEach(key => {
					total += this.combinations[key].combinantionPrice * this.combinations[key].quantity 
				});
				return this.formatTHIS(total, 2);
			}
		},
		methods: {
			showModal: function(name, product) {
				this.loadCombinations(product);
				$("#"+name).modal('show');
			},
			loadInventories: function() {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement'});
				let uri = "/admin/stocks/inventories-filtered";

				this.filter.post(uri).then(response => {
					loading.close();
					this.inventories = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadCombinations: function(id){
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement'});
				let uri = "/admin/stocks/combinations";
				axios.post(uri, {variable_product_id: id}).then(response => {
					loading.close();
					this.combinations = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadPartners: function() {
				let uri = "/admin/partners/activated-sellpoints-with-stockable-products";

				axios.get(uri).then(response => {
					this.partners = response.data;
				}).catch(err => {
					console.log(err);
				});
			},
		},
		created: function() {
			this.loadInventories();
			this.loadPartners();
		},
		mounted(){
			console.log('test');
		}
	}
</script>
<style>
	.cursor-pointer{
		cursor:pointer;
	}
</style>